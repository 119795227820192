import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import { includes } from 'ramda';
import getConfig from 'next/config';
import { IconTile } from '@/components/ui';
import { NextConfig } from '@/interfaces';
import { AmplitudeEventName, logAnalyticsEvent } from '@/utils/AnalyticsHelper';
import { PAGE_PROPERTIES, ROUTING_PROPERTIES } from '@/constants';
import childCare from '../../../../../assets/images/child-care.png';
import seniorCare from '../../../../../assets/images/senior-care.png';
import household from '../../../../../assets/images/household.png';

const { publicRuntimeConfig } = getConfig() as NextConfig;
const { HOMEPAY_URL, HOUSEHOLD_URL } = publicRuntimeConfig;

export const RIGHT_CHOICES = [
  {
    iconSrc: childCare,
    label: 'Child care',
    url: null,
  },
  {
    iconSrc: seniorCare,
    label: 'Senior care',
    url: `${HOMEPAY_URL}${ROUTING_PROPERTIES.seniorCare.relativeURL}`,
  },
  {
    iconSrc: household,
    label: 'Household',
    url: HOUSEHOLD_URL,
  },
];

const RightChoice = () => {
  const choices = RIGHT_CHOICES.map((choice) => {
    const iconTile = (
      <Box
        key={choice.label}
        sx={{
          marginBottom: {
            xs: '4px',
            md: '0px',
          },
        }}>
        <IconTile iconSrc={choice.iconSrc} key={choice.label} label={choice.label} />
      </Box>
    );

    if (choice.url) {
      const href = includes('http', choice.url) ? choice.url : `/${choice.url}`;

      return (
        <Link
          href={href}
          key={choice.label}
          onClick={() => {
            logAnalyticsEvent({
              data: {
                cta_location: 'HomePay VHP',
                cta_text: choice.label,
              },
              name: AmplitudeEventName.ctaInteracted,
              pageProperties: PAGE_PROPERTIES.mainVHP,
            });
          }}>
          {iconTile}
        </Link>
      );
    }
    return iconTile;
  });

  return (
    <Container
      sx={{
        padding: theme.spacing(8, 0, 8),
      }}>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Typography
              variant="display3"
              fontSize={[28, 36]}
              textAlign="center"
              mb={7}
              mt={0}
              mx={[1, 14]}
              lineHeight="34px">
              The right choice for all&nbsp;household employers
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            data-testid="choices-wrapper"
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            gap={2}>
            {choices}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RightChoice;
