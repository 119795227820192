import { Card } from '@care/react-component-lib';
import { CardContent, Grid, Typography, Link } from '@mui/material';
import { FC, isValidElement } from 'react';
import Image, { StaticImageData } from 'next/image';
import NextLink from 'next/link';
import { Icon24UtilityChevronRight } from '@care/react-icons';
import { useMobileQuery } from '@/utils/hooks';
import { AmplitudeEventName, logAnalyticsEvent } from '@/utils/AnalyticsHelper';
import { PAGE_PROPERTIES } from '@/constants';

interface Props {
  description: string;
  icon: StaticImageData | Element;
  label: string;
  mobileDescription?: string;
  url: string;
}

const ServiceTile: FC<Props> = ({ description, icon, label, mobileDescription, url }) => {
  const isMobile = useMobileQuery();

  const handleTileClick = () => {
    logAnalyticsEvent({
      data: {
        cta_location: 'HomePay VHP Variant 2 Hero',
        cta_text: label,
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties: PAGE_PROPERTIES.mainVHP,
    });
  };

  return (
    <Grid
      item
      md={3}
      xs={12}
      sx={{
        display: 'flex',
        width: '100%',
      }}>
      <NextLink href={url} passHref>
        <Link
          sx={{
            display: 'flex',
            width: '100%',
          }}
          href={url}
          key={label}
          onClick={handleTileClick}
          underline="none">
          <Card
            careVariant="subtle"
            sx={{
              opacity: {
                xs: 0.85,
                md: 1,
              },
              width: '100%',
            }}>
            <CardContent
              sx={{
                height: '100%',
              }}>
              <Grid
                sx={{
                  height: '100%',
                }}
                container
                direction={isMobile ? 'row' : 'column'}
                wrap="nowrap">
                {isValidElement(icon) ? (
                  <>{icon}</>
                ) : (
                  <Image
                    alt="Service icon"
                    height={45}
                    layout="fixed"
                    src={icon as StaticImageData}
                    width={45}
                  />
                )}
                <Grid container direction="column">
                  <Typography variant="h3">{label}</Typography>
                  <Typography variant="body3" color="secondary">
                    {mobileDescription && isMobile ? mobileDescription : description}
                  </Typography>
                </Grid>
                {!isMobile && (
                  <Grid
                    item
                    sx={{
                      margin: 'auto 0 0 auto',
                    }}>
                    <Icon24UtilityChevronRight />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Link>
      </NextLink>
    </Grid>
  );
};

export default ServiceTile;
