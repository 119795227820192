import { Box, Container, Grid, Hidden, Typography } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import { useState } from 'react';
import Image from 'next/image';
import ModalVideo from '@/components/pages/shared/ModalVideo/ModalVideo';
import { PlayButtonLink } from '@/components/ui';
import { HOW_HP_HELPS_LAURA_VIDEO_URL, NANNY_HELP_FEATURES, PAGE_PROPERTIES } from '@/constants';
import { AmplitudeEventName, logAnalyticsEvent } from '@/utils/AnalyticsHelper';
import { useMobileQuery } from '@/utils/hooks';
import nannyHelpMW from '../../../../../assets/images/nannyhelp-mw.png';
import nannyHelp from '../../../../../assets/images/nannyhelp.png';
import check from '../../../../../assets/images/check.png';

const NannyHelp = () => {
  const [isVisible, setVisibility] = useState(false);
  const isMobile = useMobileQuery();
  const rightImageSrc = isMobile ? nannyHelpMW : nannyHelp;

  const toggleModalVisibility = () => setVisibility((_isVisible) => !_isVisible);

  const handleVideoButtonClick = () => {
    toggleModalVisibility();
    logAnalyticsEvent({
      data: {
        cta_location: 'homepayhelps',
        cta_text: 'PlayVideo_Laura',
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties: PAGE_PROPERTIES.mainVHP,
    });
  };

  const renderFeatureList = () =>
    NANNY_HELP_FEATURES.map((feature) => (
      <li key={feature} style={{ display: 'flex', margin: '0 0 14px 0' }}>
        <Image height={24} width={24} src={check} alt="nanny-image" />
        <Typography component="span" variant="body1" fontSize={[16, 16, 18]} ml={1.5} mb={0}>
          {feature}
        </Typography>
      </li>
    ));

  const renderContentGrid = () => (
    <Grid
      sx={{
        [theme.breakpoints.down('md')]: {
          margin: '0 auto',
        },
      }}
      item
      md={7}
      sm={12}>
      <Box
        sx={{
          '& .feature-list-wrapper': {
            display: 'inline-flex',
            margin: '0 auto',
          },

          '& h2': {
            [theme.breakpoints.up('md')]: {
              fontSize: '36px',
              lineHeight: '42px',
              padding: '0px 50px 40px 0',
            },
            color: theme.palette.text.primary,
            fontSize: '28px',
            fontWeight: 'bold',
            lineHeight: '34px',
            padding: '40px 0px 32px 24px',
          },

          '& li': {
            listStyleType: 'none',
            marginBottom: '8px',
            paddingLeft: '0px',
          },

          '& p': {
            fontSize: '18px',
            lineHeight: '24px',
            marginBottom: '10px',
          },

          [theme.breakpoints.up('md')]: {
            borderRadius: '16px',
            height: '442px',
            padding: '56px 0 0 66px',
            textAlign: 'left',
          },
          [theme.breakpoints.up('xl')]: {
            marginLeft: '70px',
          },

          '& ul': {
            [theme.breakpoints.up('md')]: {
              fontSize: '18px',
              lineHeight: '24px',
              paddingLeft: '0',
            },
            marginBottom: '10px',
            marginTop: '16px',
            paddingLeft: '0px',
            textAlign: 'left',
          },

          backgroundColor: theme.palette.care?.grey[50],
          padding: '0 0 40px',
          textAlign: 'center',
        }}>
        <Typography data-testid="nanny-help-heading" variant="h2">
          HomePay helps your nanny, too
        </Typography>
        <Typography component="p">
          Paying legally helps your nanny&nbsp;
          <Hidden smUp>
            <br />
          </Hidden>
          qualify for:
        </Typography>
        <Box className="feature-list-wrapper">
          <ul data-testid="feature-list">{renderFeatureList()}</ul>
        </Box>
        <Box>
          <PlayButtonLink data-testid="watch-story-link" onClick={handleVideoButtonClick}>
            See how HomePay helped Laura
          </PlayButtonLink>
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Container
      sx={{
        marginBottom: theme.spacing(3.625),
        padding: theme.spacing(12, 6, 4, 6),
        [theme.breakpoints.down('md')]: {
          backgroundColor: theme.palette.care?.grey[50],
          padding: 0,
        },
      }}
      maxWidth="xl">
      <Grid container spacing={0}>
        {!isMobile && renderContentGrid()}
        <Grid
          sx={{
            '& span': {
              overflow: 'unset !important',
            },
            position: 'relative',
            width: '100%',
          }}
          item
          md={5}
          sm={12}>
          <Box
            sx={{
              [theme.breakpoints.up('md')]: {
                bottom: 'unset !important',
                left: '-60px !important',
                position: 'absolute',
                right: 'unset !important',
                top: '-48px !important',
                zIndex: 100,
              },
            }}>
            <Image
              alt="Nanny help"
              data-testid="nanny-help-right-image"
              height={!isMobile ? 434 : 316}
              layout={isMobile ? 'responsive' : 'intrinsic'}
              loading="lazy"
              src={rightImageSrc}
              width={!isMobile ? 570 : 375}
              style={{
                borderRadius: isMobile ? 0 : '16px',
              }}
            />
          </Box>
        </Grid>
        {isMobile && renderContentGrid()}
        <ModalVideo
          dataTestId="homepage-how-homepay-helps-modal"
          isVisible={isVisible}
          onClose={toggleModalVisibility}
          title="See how HomePay helps Laura"
          videoUrl={HOW_HP_HELPS_LAURA_VIDEO_URL}
        />
      </Grid>
    </Container>
  );
};

export default NannyHelp;
