import { Box, Container, Grid, Typography } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import Image from 'next/image';
import { FC } from 'react';
import { useMobileQuery } from '@/utils/hooks';
import Tiles from './Tiles';

interface FeatureSectionProps {
  title: string;
  imageSrc: string;
  imageAlt: string;
  titleOnRight?: boolean;
  hideNumberBackground?: boolean;
  hideTileDescriptionMarginTop?: boolean;
}

const FeatureSection: FC<FeatureSectionProps> = ({
  title,
  imageSrc,
  imageAlt,
  titleOnRight,
  hideNumberBackground,
  hideTileDescriptionMarginTop,
}) => {
  const isMobile = useMobileQuery();

  return (
    <Container
      sx={{
        overflow: 'hidden',
        padding: {
          xs: theme.spacing(6, 0),
          md: theme.spacing(6, 2),
        },
      }}>
      <Grid container direction="row" justifyContent="center">
        {isMobile && (
          <Grid item xs={12}>
            <Image
              alt={imageAlt}
              data-testid="feature-section-img"
              height={344}
              layout="responsive"
              loading="lazy"
              objectFit="fill"
              src={imageSrc}
              width={500}
            />
          </Grid>
        )}
      </Grid>
      {!titleOnRight && (
        <Grid container>
          <Grid item xs={12}>
            <Box textAlign="center">
              <Typography textAlign="center" variant="display3">
                {title}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} md={6}>
          {!isMobile && (
            <Image
              alt={imageAlt}
              data-testid="feature-section-img"
              height={352}
              layout="responsive"
              loading="lazy"
              src={imageSrc}
              objectFit="fill"
              width={512}
              style={{
                borderRadius: 16,
              }}
            />
          )}
        </Grid>
        <Grid item md={6} sm={12}>
          {titleOnRight && (
            <Typography textAlign="center" variant="display3">
              {title}
            </Typography>
          )}
          <Tiles
            hideNumberBackground={hideNumberBackground}
            hideTileDescriptionMarginTop={hideTileDescriptionMarginTop}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default FeatureSection;
