import { theme } from '@care/material-ui-theme';
import { Grid, Container } from '@mui/material';
import getConfig from 'next/config';
import { IconIllustrationSmallCalculator, IconIllustrationSmallNannies } from '@care/react-icons';
import { StaticImageData } from 'next/image';
import { NextConfig } from '@/interfaces';
import SampleNannyContract from '@/assets/images/instant-payment.png';
import { ROUTING_PROPERTIES } from '@/constants';
import ServiceTile from './ServiceTile';

const { publicRuntimeConfig } = getConfig() as NextConfig;
const { HOMEPAY_URL, NTG_URL, SNC_URL } = publicRuntimeConfig;

export const SERVICES = [
  {
    icon: <IconIllustrationSmallCalculator size={45} />,
    label: 'Budget & tax calculator',
    description: 'Access our household employer budget and tax calculators',
    mobileDescription: 'Access our household employer tools',
    url: `${HOMEPAY_URL}${ROUTING_PROPERTIES.nannyTaxCalculator.relativeURL}`,
  },
  {
    icon: SampleNannyContract,
    label: 'Sample nanny contract',
    description: 'Get more money back this tax season',
    url: SNC_URL,
  },
  {
    icon: <IconIllustrationSmallNannies size={45} />,
    label: 'Nanny tax guide',
    description: "Let us handle it so you don't have to",
    url: NTG_URL,
  },
];

const Services = () => {
  return (
    <Container
      sx={{
        [theme.breakpoints.down('md')]: {
          overflow: 'hidden',
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(1.875),
        },
        position: 'relative',
      }}>
      <Grid
        container
        sx={{
          zIndex: 20,
        }}
        data-testid="services-container"
        direction="row"
        justifyContent="center"
        spacing={3}>
        {SERVICES.map((service) => (
          <ServiceTile
            description={service.description}
            icon={service.icon as StaticImageData | Element}
            label={service.label}
            mobileDescription={service.mobileDescription && service.mobileDescription}
            url={service.url}
            key={service.label}
          />
        ))}
      </Grid>
    </Container>
  );
};

export default Services;
