import GetStarted from '../../shared/GetStarted/variant2';
import HeroImage from '../../../../assets/images/nannyhelp.png';

const Hero = () => {
  return (
    <GetStarted
      heading="Pay your nanny legally, save time and money"
      subHeading="Let our HomePay experts handle your household tax and payroll needs."
      heroImageUri={HeroImage}
    />
  );
};

export default Hero;
