import { Link } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import Footer from '@/components/Layout/Footer';
import { useMobileQuery } from '@/utils/hooks';

const HomepageFooter = () => {
  const isMobile = useMobileQuery();

  return (
    <Footer
      featured={[
        <p>
          <Link
            sx={{
              color: theme.palette.care?.grey[600],
              fontWeight: 400,
            }}
            href="https://www.forbes.com/sites/nextavenue/2019/06/12/ways-to-vet-a-caregiver-or-caregiving-agency/#546bd23a8a7f"
            target="_blank">
            Ways To Vet A Caregiver Or Caregiving Agency
          </Link>{' '}
          From Forbes. © 2019 Forbes. All rights reserved. Used under license.
        </p>,
        <p>
          <Link
            sx={{
              color: theme.palette.care?.grey[600],
              fontWeight: 400,
            }}
            href="https://www.huffpost.com/entry/senior-care_b_11057736"
            target="_blank">
            Senior Care: Hope for the Best, Prepare for the Worst
          </Link>{' '}
          From HuffPost. © 2016 Oath Inc.. All rights reserved. Used under license.
        </p>,
      ]}
      isMobile={isMobile}
    />
  );
};

export default HomepageFooter;
