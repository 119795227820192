import { Box, Container, Grid, Typography } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import Image from 'next/image';
import CheckedDocumentIcon from '../../../../../assets/images/checked-document.svg';
import img from '../../../../../assets/images/special-needs.png';
import StarIcon from '../../../../../assets/images/star.svg';
import { GuaranteeItem } from '.';

const Guarantee = () => {
  return (
    <Container
      sx={{
        [theme.breakpoints.up('sm')]: {
          margin: '120px auto',
        },
        margin: '0 auto 39px auto',
      }}
      maxWidth="lg">
      <Grid container>
        <Grid item sm={6} xs={12}>
          <Box alignItems="center" display="flex" flexDirection="column">
            <Image
              alt="Our happiness guarantee"
              height={100}
              layout="intrinsic"
              loading="lazy"
              src={img}
              width={100}
            />
            <Typography textAlign="center" variant="h1">
              We guarantee your
              <br />
              happiness and our service
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box
            sx={{
              padding: '16px 24px',
              textAlign: 'center',
              [theme.breakpoints.up('sm')]: {
                padding: '10px 30px 0 0',
                textAlign: 'left',
              },
              [theme.breakpoints.up('xl')]: {
                paddingRight: '128px',
              },
            }}>
            <GuaranteeItem
              description="If HomePay doesn't meet your expectations for an all-in-one nanny tax and payroll service during your first six months of use, we'll refund your payments for our services.**"
              serviceIcon={<StarIcon />}
              title="Happiness guarantee"
            />
            <Box pt="24px" />
            <GuaranteeItem
              description="We guarantee that your tax returns are filed accurately and on time.***"
              serviceIcon={<CheckedDocumentIcon />}
              title="Service guarantee"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Guarantee;
