import HowHomepayWorks from '@/assets/images/how-homepay-works.png';
import FeatureSection from '@/components/pages/shared/FeatureSection';

export default function HowItWorks() {
  return (
    <FeatureSection
      title="How it works"
      imageSrc={HowHomepayWorks.src}
      imageAlt="How Homepay works"
    />
  );
}
