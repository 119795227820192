import { Box } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import { useMobileQuery } from '@/utils/hooks';
import { reviews } from '@/constants';
import Testimonials from '../../shared/Testimonials';
import familyDependsMW from '../../../../assets/images/familydepends-mw.jpg';
import familyDepends from '../../../../assets/images/familydepends.png';

const Reviews = () => {
  const isMobile = useMobileQuery();
  const imageURI = isMobile ? familyDependsMW : familyDepends;

  return (
    <Box
      sx={{
        padding: theme.spacing(12, 7, 2.5, 11),
        [theme.breakpoints.down('md')]: {
          padding: 0,
        },
      }}>
      <Testimonials title="Families depend on HomePay" imageURI={imageURI} reviews={reviews} />
    </Box>
  );
};

export default Reviews;
