import { Grid } from '@mui/material';
import { FC } from 'react';
import NumberedTile from './NumberedTile';

interface TilesProps {
  hideNumberBackground?: boolean;
  hideTileDescriptionMarginTop?: boolean;
}

const features = [
  {
    description:
      "Set up a pay cadence that works for you, and we'll onboard your employees to save you time.",
    number: 1,
    title: 'Customized payroll process',
  },
  {
    description:
      'We prepare your quarterly and year-end taxes - filed on-time, every time. Guaranteed.',
    number: 2,
    title: 'Tax returns filed automatically',
  },
  {
    description:
      'Contact our award-winning tax, payroll & HR specialists to have your questions answered quickly and accurately.',
    number: 3,
    title: 'Unlimited access to experts',
  },
];

const Tiles: FC<TilesProps> = ({ hideNumberBackground, hideTileDescriptionMarginTop }) => {
  return (
    <Grid container direction="column" data-testid="tiles-container">
      {features.map((feature) => (
        <Grid item key={feature.number}>
          <NumberedTile
            number={feature.number}
            title={feature.title}
            description={feature.description}
            hideNumberBackground={hideNumberBackground}
            hideTileDescriptionMarginTop={hideTileDescriptionMarginTop}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Tiles;
