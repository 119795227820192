import { Box, Hidden, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  description: string;
  serviceIcon: ReactNode;
  title: string;
}

const GuaranteeItem: FC<Props> = ({ description, serviceIcon, title }) => {
  return (
    <Box
      sx={{
        display: {
          sm: 'inline-flex',
        },
        '& img': {
          marginRight: {
            sm: 12,
          },
        },
      }}>
      <Hidden mdDown>
        <Box marginRight="12px" width="24px">
          {serviceIcon}
        </Box>
      </Hidden>
      <Typography variant="body1">
        <Hidden mdDown>
          <b>{title}:</b>{' '}
        </Hidden>
        {description}
      </Typography>
    </Box>
  );
};

export default GuaranteeItem;
